import '../channels'

import '@hotwired/turbo-rails'
Turbo.session.drive = false // eslint-disable-line
import '~/application.css'

// Font Awesome
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)
